
import React, { useContext, useRef, useState } from 'react'
import AuthContext from '../../../store/auth-context';
import { objectNewGameFilled } from '../../../utils/Utils';
import * as URLConstants from '../../../utils/Constants'
import { useNavigate } from 'react-router';




function AddWishListgame({games,setupdate}) {
    const nameInputRef = useRef();
    const authCtx = useContext(AuthContext)
    const navigate = useNavigate()
    const [enteredGame, setenteredGame] = useState('')
    const [errorMsg, setErrorMessage] = useState('')
    const [okMessage, setOkMessage] = useState('')  
    const [enteredType, setenteredType] = useState(1)

    const nameInputChangeHandler = event => {
        setenteredGame(event.target.value);
    };

    const formSubmissionHandler = event => {
        event.preventDefault();

  
        const enteredName = nameInputRef.current.value

        const enteredTypeVal = enteredType

        const objToSend = {
          id: null,
          name: enteredName,
          game_order: getLastGameOrder(games, enteredTypeVal),
          id_type:3,
          review: '',
          score: 0
        }
  
     
        if(objectNewGameFilled(objToSend)){
      
          setErrorMessage("");
          const token = 'Bearer ' + authCtx.token;

          fetch(URLConstants.URL_GAMES,
          {
            method: 'POST',
            body: JSON.stringify(objToSend),
            headers: {
              'Content-Type':'application/json',
              'Authorization': token
            }
          }
          ).then(res => {
            if(res.ok) {
              return res.json().then((data) => {
                  setOkMessage(data.message)
                  setupdate(previousState => !previousState)
                  setenteredGame("")
              })
            }else{
              if(res.status == 401){
                navigate("/login")
              }
              return res.json().then((data) => {
              
              })
            }
          });
        }else{
          setErrorMessage("Povinné pole nevyplněna");
        }
 
    };

  return (
      <>
    <h5>Přidat hru do wishlistu</h5>
    <form onSubmit={formSubmissionHandler}>
        <label className='d-block' htmlFor='name'>Název hry</label>
        <input ref={nameInputRef} type="text" id='name' onChange={nameInputChangeHandler} value={enteredGame} />
        <button className='d-block btn btn-success mt-3'>Přidat</button>
    </form>
    <p style={{color: 'red'}}>{errorMsg}</p>
    <p style={{color: 'green'}}>{okMessage}</p>
    </>
  )
}

function getLastGameOrder(games, id_type){
  let max = 0;
  const filteredGames =  games.filter(item => item.id_type === id_type)
  for (let index = 0; index < filteredGames.length; index++) {
    const element = filteredGames[index];
    if(element.game_order > max){
      max = element.game_order
    }
  }
  max++;
  return max; 
}

export default AddWishListgame