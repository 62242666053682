import React, { useState } from 'react'
import AdminGamePanel from './AdminGamePanel'
import AdminMoviesPanel from './AdminMoviesPanel'
import AdminSchedulePanel from './AdminSchedulePanel'



function Admin() {
  

  

  const [windowSelect, setwindowSelect] = useState(1)

  const changeWindow = (id) => {
    setwindowSelect(id);
  }

 
  

  return (
    <>
   <h1>Admin panel</h1>
 
     <button type='button' className={`${windowSelect === 1 ? "active" : ""} btn btn-outline-primary `} onClick={() => {changeWindow(1)}}>Hry</button>
     <button type='button' className={`${windowSelect === 2 ? "active" : ""} btn btn-outline-primary ms-2`} onClick={() => {changeWindow(2)}} >Rozvrh</button>
     <button type='button' className={`${windowSelect === 3 ? "active" : ""} btn btn-outline-primary ms-2`} onClick={() => {changeWindow(3)}} >Filmy/serialy/knihy</button>
  
   <hr />
   <div className='row'>
      {windowSelect === 1 && <AdminGamePanel />}
      {windowSelect === 2 && <AdminSchedulePanel />}
      {windowSelect === 3 && <AdminMoviesPanel />}
      
  
      </div>


    </>
  )
}

 /**
  *       
  */


export default Admin